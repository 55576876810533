.container {
    width: 100svw;
    overflow-x: hidden;
}

.home {
    background-image: url("../../assets/phx.JPG");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
}

.title {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}


.content {
    flex-basis: 90%;
    padding: 16px;
    height: auto
}

/*small*/
@media screen and (min-width: 600px) {
    .content {
        flex-basis: 95%;
    }
}

/*medium*/
@media screen and (min-width: 960px) {
   
}

/*large & extra larg*/
@media screen and (min-width: 1280px) {
    .content {
        flex-basis: 75%;
        display: flex;
        flex-flow: row wrap;
        max-height: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        max-height: calc(100vh - 2.75rem);
    }
}

/* @media screen and (max-width: 600px) {
    .content {
        flex-basis: 90%;
        padding: 16px;
        height: auto;
    }
} */

/* @media screen and (min-width: 601px) {
    .content {
        flex-basis: 75%;
        overflow: hidden;
    }
} */