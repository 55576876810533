.experienceList {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

}

.title {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.experienceListItem {
    flex-basis: 33%;
    text-align: center;
    margin: 1rem 0 1rem 0;
}

@media screen and (max-width: 600px) {
    .experienceListItem {
        flex-basis: 100%;
        text-align: center;
        margin: 1rem 0 1rem 0;
    }
}