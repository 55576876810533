.partnerCardContainer {
    flex-basis: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}

.card {
    margin-top: 1rem;
    flex-basis: 80%;
    border-radius: 5px;
    box-shadow: 2px 3px 2px 1px #cccccc68;
    display: flex;
    flex-flow: row wrap;
    border: 1px solid #ccc;
    padding-bottom: 8px;
}

.scheduleBtn {
    margin: 1rem 0 8px 0;
    flex-basis: 85%;
}

.partnerImage {
    border-radius: 5px 5px 0 0;
    width: 100%;
}

.contactLine {
    flex-basis: 100%;
    margin: 8px 8px 0 8px;
}

.contactLink {
    text-decoration: none;
    color: black;
}


/*small*/
@media screen and (min-width: 600px) {
    .card {
        flex-basis: 60%;
    }
}

/*medium*/
@media screen and (min-width: 960px) {
    .card {
        flex-basis: 40%;
    }
}

/*large & extra larg*/
@media screen and (min-width: 1280px) {
    .partnerCardContainer {
        flex-basis: 15%;
        display: flex;
        flex-flow: row wrap;
    }
    
    .card {
        flex-basis: 100%;
        border-radius: 5px;
        box-shadow: 2px 3px 2px 1px #cccccc68;
        display: flex;
        flex-flow: row wrap;
        border: 1px solid #ccc;
        padding-bottom: 8px;
    }

    .scheduleBtn {
        margin: 8px 0 8px 0;
        flex-basis: 100%;
    }
}