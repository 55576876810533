

.hero {
    height: 100vh;
    width: 100vw;
    z-index: 10;
    background-color: #00000090;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    color: white;
}

