.title {
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.mapWindow {
    width: 100%;
    border: none;
    height: 25rem;
}