.heroTitleContainer {
    flex-basis: 100%;
    display: flex; 
    flex-flow: row wrap;
    justify-content: center;
}

.heroTitleTop {
    flex-basis: 100%;
    text-align: center;
    font-size: 2.5rem;
    margin: 0
}

.divider {
    border-top: 1px solid #efefef;
    flex-basis: 60%;
}

.heroTitleBottom {
    flex-basis: 100%;
    text-align: center;
    font-size: 2rem;
}

@media screen and (max-width: 600px) {
    .heroTitleTop { font-size: 1.75rem; }

    .divider { flex-basis: 75%; }

    .heroTitleBottom { font-size: 1.25rem; }
}