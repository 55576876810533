.calendarContainer {
    height: 500px;
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
}

.calendar {
    width: 20rem;
    height: 400px;
}